import { Layout, LayoutProps } from 'react-admin';
import Header from "./Header";
import Menu from './Menu';

export type LayoutStylesProps = {
    /**
     * Styles applied to root container
     */
    root?: any;
    /**
     * Styles applied to second container
     */
    container?: any;
    /**
     * Styles applied to appBar
     */
    appBar?: any;
    /**
     * Styles applied to sidebar when is open
     */
    sidebarWhenOpen?: any;
    /**
     * Styles applied to sidebar when is closed
     */
    sidebarWhenClosed?: any;
    /**
     * Styles applied to menu list items
     */
    listItem?: any;
    /**
     * Styles applied to user profile menu link
     */
    menuLink?: any;
    /**
     * Styles applied to logout button
     */
    logoutButton?: any;
    /**
     * Styles applied to user profile avatar
     */
    avatar?: any
};

export interface LayoutExtProps extends LayoutProps {
    views?: any[];
};

const LayoutExt = (props: LayoutExtProps) => (
    <Layout
        {...props}
        appBar={Header}
        //sidebar={CustomSidebar}
        menu={Menu}
    />
);

export default LayoutExt;