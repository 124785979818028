import { memo, useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import {
    Box,
    Grid,
    Stack,
    Typography,
} from '@mui/material';

import { CommonProps } from './index'

import { useStore } from 'react-admin';
import { AppInfo, AppStatusType } from 'publicapp/types';
import { AppIcon } from 'publicapp/apps/base';

import { CompareValues } from 'admin/core/utils';
import { Clock } from './Clock';
import WinIcon from './WinIcon';

interface TaskbarProps extends CommonProps {
}

export const Taskbar = memo((props: TaskbarProps) => {
    const theme = useTheme();
    const [applist, setApplist] = useStore<AppInfo[]>('applist');
    const [activeApp, setActiveApp] = useState<AppInfo | undefined>({} as AppInfo);
    const { isSmall } = props;

    useEffect(() => {
        let lastActive = applist?.find(x => x.active);
        setActiveApp(lastActive);
        return () => { };
    }, [JSON.stringify(applist)])

    return (
        <Box
            id="taskbar"
            sx={{
                bottom: 0,
                height: '48px',
                width: '100vw',
                position: 'absolute',
                color: theme.palette.common.black,
                backgroundColor: "rgba(243, 243, 243, .85)",
                backdropFilter: 'saturate(3) blur(20px)',
            }}
        >
            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ height: '100%' }}>
                <Grid item sx={{ height: '100%' }}>
                    <Grid container alignItems="center" justifyContent='center' sx={{ height: '100%', pl: 1 }}>
                        <Grid item>
                            <WinIcon />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{ height: '100%' }}>
                    <Grid container alignItems="center" justifyContent='center' sx={{ height: '100%' }}>
                        {
                            applist?.sort(CompareValues("order")).filter(x => x.status === AppStatusType.minimized || x.status === AppStatusType.opened).map((app, index) => {
                                return (
                                    <Grid key={app.name} item>
                                        <AppIcon
                                            key={index}
                                            name={app.name}
                                            iconUrl={app.iconUrl}
                                            label={app.label}
                                            inTaskBar
                                            mimized={app?.status === AppStatusType.minimized}
                                            selected={activeApp?.name === app.name}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>

                <Grid item sx={{ height: '100%' }}>
                    <Grid container alignItems="center" justifyContent='center' sx={{ height: '100%', pr: 1 }}>
                        <Grid item>
                            <Clock />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Box >
    )
})