import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Tooltip } from '@mui/material';

import {
    ButtonProps,
    RaRecord,
    useDataProvider,
    useRecordContext,
    useNotify,
    useTranslate,
    useRefresh,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import Icon from '@mui/icons-material/Send';
import PendingIcon from '@mui/icons-material/HourglassBottom';
import SuccessIcon from '@mui/icons-material/Check';
import { green, yellow } from '@mui/material/colors';

import { ConfirmButton, LoadingPage } from 'admin/components';
import { Invoice, InvoiceSubmission, InvoiceSubmissionOutcomeType } from 'admin/types';

interface Props<RecordType extends RaRecord = any> {
    record?: RecordType;
    resource?: string;
}

export type SendInvoiceButtonProps<RecordType extends RaRecord = any> = Props<
    RecordType
> &
    ButtonProps;

export const SendInvoiceButton = <RecordType extends RaRecord = any>(
    props: SendInvoiceButtonProps<RecordType>
) => {
    const {
        ...rest
    } = props;

    const dataProvider = useDataProvider();
    const record = useRecordContext<Invoice>();
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();

    const [loading, setLoading] = useState(false);

    const [label, setLabel] = useState('invoices.action.xml.label');
    const [icon, setIcon] = useState(<Icon />);

    useEffect(() => {
        if (record.currentInvoiceSubmission?.outcome) {
            switch (record.currentInvoiceSubmission.outcome) {
                default:
                case InvoiceSubmissionOutcomeType.NotSent:
                case InvoiceSubmissionOutcomeType.Failed:
                    setLabel('invoices.action.xml.label');
                    setIcon(<Icon sx={{ color: green[500] }} />);
                    break;
                case InvoiceSubmissionOutcomeType.Pending:
                    setLabel('invoices.action.xml.processing');
                    setIcon(<PendingIcon sx={{ color: yellow[700] }} />);
                    break;
                case InvoiceSubmissionOutcomeType.Success:
                    setLabel('invoices.action.xml.success');
                    setIcon(<SuccessIcon sx={{ color: green[500] }} />);
                    break;
            }
        }
    }, [record.currentInvoiceSubmission?.outcome])

    const onConfirm = async () => {
        if (record) {
            console.log(record.currentInvoiceSubmission)
            if (record.currentInvoiceSubmission?.outcome === InvoiceSubmissionOutcomeType.Pending) {
                setLoading(true);

                dataProvider.update('invoicesubmissions', {
                    id: record.currentInvoiceSubmission.id,
                    data: { outcome: InvoiceSubmissionOutcomeType.Success },
                    previousData: record.currentInvoiceSubmission
                }).then((response) => {
                    const { data } = response || { data: {} as InvoiceSubmission };
                    if (data) {
                        console.log(data);
                        refresh();
                    }
                }).catch(error => {
                    console.log(error);
                    notify('Error', { type: 'warning' })
                }).finally(() => {
                    setLoading(false);
                });
            }
            else if (record.currentInvoiceSubmission?.outcome !== InvoiceSubmissionOutcomeType.Success) {
                setLoading(true);

                dataProvider.post(`api/invoices/sendXml/${record.id}`).then((response) => {
                    const { data } = response || { data: false };
                    if (data) {
                        notify('invoices.action.xml.success', { type: "success" })
                    }
                    else {
                        notify('invoices.action.xml.failed', { type: "warning" })
                    }
                }).catch(error => {
                    console.log(error);
                    notify('Error', { type: 'warning' })
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    };

    if (loading) return <LoadingPage loadingPrimary='invoices.action.xml.processing' loadingSecondary='' fullscreen />

    return (
        <Tooltip title={translate(label)}>
            <Box>
                <ConfirmButton
                    label=""
                    icon={icon}
                    title={record.currentInvoiceSubmission?.outcome === InvoiceSubmissionOutcomeType.Pending ? 'invoices.action.xml.confirmPending' : 'invoices.action.xml.confirm'}
                    content={record.currentInvoiceSubmission?.outcome === InvoiceSubmissionOutcomeType.Pending ? 'invoices.action.xml.confirmPendingContent' : 'invoices.action.xml.confirmContent'}
                    onConfirm={onConfirm}
                    onlyButton={record.currentInvoiceSubmission?.outcome === InvoiceSubmissionOutcomeType.Success}
                    {...rest}
                />
            </Box>
        </Tooltip>
    )
}

SendInvoiceButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.any,
};