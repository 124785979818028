import { useTheme } from '@mui/material/styles';

import {
    Box,
    Stack,
} from '@mui/material';

import { CommonProps } from './index';

import iconImg from '../img/apps/home.png';

export interface WinIconProps extends CommonProps {
}

const WinIcon = (props: WinIconProps) => {
    const theme = useTheme();

    const handleClick = () => {
    };

    return (
        <Stack
            id={'win_ico'}
            spacing={.5}
            justifyContent="center"
            alignItems="center"
            onClick={handleClick}
            sx={{
                margin: `0 ${theme.spacing(1)}`,
                padding: theme.spacing(.5),
                fontSize: '1em',
                transition: 'all ease-in-out .2s',
                cursor: 'pointer',
                borderRadius: theme.spacing(1),
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, .67)',
                }
            }}>
            <Box display='flex' justifyContent="center" alignItems="center" >
                <img src={iconImg} width={theme.spacing(3.5)} />
            </Box>
        </Stack>
    )
}

export default WinIcon;