import { AuthProvider } from 'react-admin';
import jwtProvider from './jwtProvider';
import { Utils } from '../core';

const apiEndpoint = Utils.GetENV('API_ENDPOINT');
const loginResource = "auth/login";
const refreshTokenResource = "auth/refreshtoken";

const authProvider: AuthProvider = {
    login: async ({ username, email, password, providerType, providerId, profilePhotoSrc }): Promise<{ redirectTo?: string | boolean } | void | any> => {
        let loginRequest = {
            username: username,
            email: email,
            password: password,
            providerType: providerType,
            providerId: providerId,
            profilePhotoSrc: profilePhotoSrc
        };

        const request = new Request(`${apiEndpoint}/${loginResource}`, {
            method: 'POST',
            body: JSON.stringify(loginRequest),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        jwtProvider.setRefreshTokenEndpoint(`${apiEndpoint}/${refreshTokenResource}`);

        return fetch(request)
            .then(response =>
                response.json().then(text => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                }))
            )
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    if (response.status === 401 || response.status === 403) {
                        console.log(response.body.errors[0].description);
                        if (response.body && response.body.errors.length > 0 && response.body.errors[0].description) {
                            //throw new Error(`pos.auth.${response.body.errors[0].code}`);
                            throw new Error(response.body.errors[0].description);
                        }
                        else {
                            throw new Error();
                        }
                    }
                    jwtProvider.ereaseToken();
                    return Promise.reject();
                }
                return response.body;
            })
            .then(auth => {
                const { accessToken, refreshToken } = auth;
                jwtProvider.setToken(accessToken, refreshToken);
                return Promise.resolve({ redirectTo: '/admin' });
            })
            .catch((error) => {
                throw error
            });
    },
    logout: () => {
        jwtProvider.ereaseToken();
        return Promise.resolve();
    },
    checkAuth: async () => {
        const decodedToken: any = jwtProvider.getToken(true);

        if (decodedToken) {
            const { exp, change_password } = decodedToken;

            //console.log("Expire IN ", exp - ((new Date().getTime() / 1000) + 55));

            if (exp > (new Date().getTime() / 1000) + 55) {
                const mustChangePassword = !Utils.IsEmpty(change_password) && JSON.parse(change_password.toLowerCase());
                if (mustChangePassword && window.location.pathname !== "/admin/profile" && window.location.search !== "?change_password=true") {
                    window.location.href = "/admin/profile?change_password=true";
                    return;
                }
                return Promise.resolve();
            }
            else {
                jwtProvider.setRefreshTokenEndpoint(`${apiEndpoint}/${refreshTokenResource}`);
                return jwtProvider.getRefreshedToken().then((gotFreshToken) => {
                    return gotFreshToken ? Promise.resolve() : Promise.reject();
                });
            }
        }
        else {
            jwtProvider.ereaseToken();
            return Promise.reject();
        }
    },
    checkError: (error) => {
        console.log(error)
        if (error?.status) {
            const status = error.status;
            if (status === 401 || status === 403) {
                jwtProvider.ereaseToken();
                return Promise.reject({ message: false });
            }
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getAccessToken: () => {
        const token = jwtProvider.getToken();
        return Promise.resolve(token);
    },
    getIdentity: () => {
        try {
            let user = GetUser() as any;
            return user && user.id ? Promise.resolve(user) : Promise.reject();
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => jwtProvider.waitForTokenRefresh().then(() => {
        try {
            let user = GetUser();
            return user?.role ? Promise.resolve(user.role) : Promise.resolve(null);
        } catch (error) {
            return Promise.reject(error);
        }
    })
};

export interface UserIdentity {
    id?: string | null;
    identityId?: string | null;
    fullName: string;
    role: string[];
    avatar?: string | null;
    [key: string]: any;
};

function GetUser() {
    const decodedToken: any = jwtProvider.getToken(true);

    let user: UserIdentity = {
        id: null,
        identityId: null,
        fullName: `Guest`,
        role: [],
        avatar: null
    };

    if (decodedToken) {
        user = {
            id: decodedToken.id,
            identityId: decodedToken.identity_id,
            fullName: decodedToken.fullName,
            role: decodedToken.rol,
            changePassword: decodedToken.change_password,
            avatar: decodedToken.picture ? `${decodedToken.picture}?lastmod=${new Date().getTime()}` : null,
        };
    }

    return user;
}

export default authProvider;
